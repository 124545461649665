:root {
  /* colors */
  --light-blue: #d6f2ff;
  --blue: #0097e0;
  --lighterblue: #01a6e2;
  --darkblue: #1f4e72;
  --almostwhite: #f7f7f7;
  --lightgray: #f5f5f5;
  --medgray: #8d8d8d;
  --darkgray: #404040;
  --red: #fd796d;
  --text: #616161;
  --almost-black: #222;

  /* misc */
  --shadow1: 0px 6px 16px -4px #cccccc;
  --mediumScreenWidth: 900px;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
}

a {
  text-decoration: none;
  color: #000;
  font-family: "Nunito Sans", sans-serif;
}

button {
  font-family: "Nunito Sans", sans-serif;
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar-thumb {
  width: 0.2px;

  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
} */
