.container {
  margin: auto -50px;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-40%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  color: #01a6e2;
  margin-bottom: 50px;
}

.swing,
.shadow {
  display: inline-block;
}

.swing div {
  border-radius: 50%;
  float: left;
  height: 1em;
  width: 1em;
}
.swing div:nth-of-type(1) {
  background: linear-gradient(to right, #01a4e2 0%, #01a4e2 100%);
}
.swing div:nth-of-type(2) {
  background: linear-gradient(to right, #0296d0 0%, #0296d0 100%);
}
.swing div:nth-of-type(3) {
  background: linear-gradient(to right, #0388be 0%, #0388be 100%);
}
.swing div:nth-of-type(4) {
  background: linear-gradient(to right, #047aac 0%, #047aac 100%);
}
.swing div:nth-of-type(5) {
  background: linear-gradient(to right, #056c9a 0%, #056c9a 100%);
}
.swing div:nth-of-type(6) {
  background: linear-gradient(to right, #065e88 0%, #065e88 100%);
}
.swing div:nth-of-type(7) {
  background: linear-gradient(to right, #095075 0%, #095075 100%);
}
.shadow {
  clear: left;
  padding-top: 1.5em;
  bottom: -75px;
}
.shadow div {
  -webkit-filter: blur(1px);
  filter: blur(1px);
  float: left;
  width: 1em;
  height: 0.25em;
  border-radius: 50%;
  background: #e3dbd2;
}
.shadow .shadow-l {
  background: #d5d8d6;
}
.shadow .shadow-r {
  background: #eed3ca;
}
@-webkit-keyframes ball-l {
  0%,
  50% {
    -webkit-transform: rotate(0) translateX(0);
    transform: rotate(0) translateX(0);
  }
  100% {
    -webkit-transform: rotate(50deg) translateX(-2.5em);
    transform: rotate(50deg) translateX(-2.5em);
  }
}
@keyframes ball-l {
  0%,
  50% {
    -webkit-transform: rotate(0) translate(0);
    transform: rotate(0) translateX(0);
  }
  100% {
    -webkit-transform: rotate(50deg) translateX(-2.5em);
    transform: rotate(50deg) translateX(-2.5em);
  }
}
@-webkit-keyframes ball-r {
  0% {
    -webkit-transform: rotate(-50deg) translateX(2.5em);
    transform: rotate(-50deg) translateX(2.5em);
  }
  50%,
  100% {
    -webkit-transform: rotate(0) translateX(0);
    transform: rotate(0) translateX(0);
  }
}
@keyframes ball-r {
  0% {
    -webkit-transform: rotate(-50deg) translateX(2.5em);
    transform: rotate(-50deg) translateX(2.5em);
  }
  50%,
  100% {
    -webkit-transform: rotate(0) translateX(0);
    transform: rotate(0) translateX(0);
  }
}
@-webkit-keyframes shadow-l-n {
  0%,
  50% {
    opacity: 0.5;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0.125;
    -webkit-transform: translateX(-1.57em);
    transform: translateX(-1.75em);
  }
}
@keyframes shadow-l-n {
  0%,
  50% {
    opacity: 0.5;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0.125;
    -webkit-transform: translateX(-1.75);
    transform: translateX(-1.75em);
  }
}
@-webkit-keyframes shadow-r-n {
  0% {
    opacity: 0.125;
    -webkit-transform: translateX(1.75em);
    transform: translateX(1.75em);
  }
  50%,
  100% {
    opacity: 0.5;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes shadow-r-n {
  0% {
    opacity: 0.125;
    -webkit-transform: translateX(1.75em);
    transform: translateX(1.75em);
  }
  50%,
  100% {
    opacity: 0.5;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.swing-l {
  -webkit-animation: ball-l 0.425s ease-in-out infinite alternate;
  animation: ball-l 0.425s ease-in-out infinite alternate;
}
.swing-r {
  -webkit-animation: ball-r 0.425s ease-in-out infinite alternate;
  animation: ball-r 0.425s ease-in-out infinite alternate;
}
.shadow-l {
  -webkit-animation: shadow-l-n 0.425s ease-in-out infinite alternate;
  animation: shadow-l-n 0.425s ease-in-out infinite alternate;
}
.shadow-r {
  -webkit-animation: shadow-r-n 0.425s ease-in-out infinite alternate;
  animation: shadow-r-n 0.425s ease-in-out infinite alternate;
}
